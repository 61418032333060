import { Element } from "@/scripts/extends";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";

export default class Collapse extends Element {
	mounted() {
		this.trigger = this.el.querySelector(".c-collapse__header");

		if (this.el.classList.contains("is-open")) {
			this.setOpenState();
		} else {
			this.setClosedState();
		}
	}

	events() {
		EventEmitter.on(EVENTS.FIXED_IMAGE_HEIGHT_RESIZE, () => this.isOpen && this.setClosedState());

		this.trigger.addEventListener("click", (e) => this.onClick(e));
	}

	onClick(e) {
		e.preventDefault();
		this.setState();
	}

	setState() {
		if (!this.isOpen) {
			this.setOpenState();
		} else {
			this.setClosedState();
		}
	}

	setOpenState() {
		this.el.classList.add("is-open");
		this.trigger.setAttribute("aria-expanded", "true");
		this.isOpen = true;
	}

	setClosedState() {
		this.el.classList.remove("is-open");
		this.trigger.setAttribute("aria-expanded", "false");
		this.isOpen = false;
	}
}
