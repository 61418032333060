import { Element } from "@/scripts/extends";

export default class Form extends Element {
	mounted() {
		this.refreshTokens();
	}

	refreshTokens() {
		// Fetch the handle of the form we want to deal with
		let handle = this.el.querySelector('input[name="handle"]').getAttribute('value');

		// Find the CSRF token hidden input, so we can replace it
		let csrfInput = this.el.querySelector('input[name="CRAFT_CSRF_TOKEN"]');

		// Fetch the new token for the form and replace the CSRF input with our new one
		fetch(`/actions/formie/forms/refresh-tokens?form=${handle}`)
			.then(result => { return result.json(); })
			.then(result => {
				csrfInput.outerHTML = result.csrf.input;

				// Find the JavaScript captcha hidden input, so we can update it
				if (result.captchas && result.captchas.javascript) {
					let jsCaptcha = result.captchas.javascript;
					this.el.querySelector('input[name="' + jsCaptcha.sessionKey + '"]').value = jsCaptcha.value;
				}

				// Find the Duplicate captcha hidden input, so we can update it
				if (result.captchas && result.captchas.duplicate) {
					let duplicateCaptcha = result.captchas.duplicate;
					this.el.querySelector('input[name="' + duplicateCaptcha.sessionKey + '"]').value = duplicateCaptcha.value;
				}
			});
	}
}
