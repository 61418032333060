import Swiper, { Autoplay, Pagination, Navigation } from "swiper";

import { Element } from "@/scripts/extends";

export default class Slider extends Element {
	mounted() {
		if (this.el.classList.contains("swiper")) {
			Swiper.use([Autoplay, Pagination, Navigation]);

			this.setSwiper();
		}
	}

	setSwiper() {
		const customConfig = JSON.parse(this.el.dataset.swiperConfig);

		if (this.el.classList.contains("timeline")) {
			customConfig.pagination.renderBullet = function (index, className) {
				return '<span class="' + className + '">' + (index + 1) + '</span>';
			};
		}

		const swiperConfig = {
			direction: 'horizontal',
			slidesPerView: 1,
			spaceBetween: 30,
			autoHeight: true,
			wrapperClass: "c-slider__wrapper",
			slideActiveClass: "is-active",
			slideClass: "c-slide",
			pagination: {
				el: '.c-slider__pagination',
				clickable: true,
			}
		};

		const mergedConfig = {
			...swiperConfig,
			...customConfig
		};

		this.swiper = new Swiper(this.el, mergedConfig);

		this.swiper.init();
	}
}
