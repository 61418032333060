import { Element } from "@/scripts/extends";

export default class Scroller extends Element {
	mounted() {
		this.prev = this.el.querySelector(".c-scroller--prev");
		this.next = this.el.querySelector(".c-scroller--next");
		this.container = this.el.querySelector(".c-scroller__container");
		this.image = this.el.querySelector(".c-scroller__image");

		this.parts = Math.floor(this.image.offsetWidth / this.container.offsetWidth);
		this.width = Math.floor(this.image.offsetWidth / this.parts);

		this.prev.addEventListener("click", () => this.handlePrev());
		this.next.addEventListener("click", () => this.handleNext());

		this.container.addEventListener("scroll", () => this.handleScroll());

		this.handleScroll();
	}

	handlePrev() {
		this.container.scrollLeft -= this.width;
	}

	handleNext() {
		this.container.scrollLeft += this.width;
	}

	handleScroll() {
		if (this.container.scrollLeft > 0) {
			this.prev.classList.add("is-active");
		} else {
			this.prev.classList.remove("is-active");
		}

		if (
			this.container.scrollLeft + this.container.offsetWidth <
			this.container.scrollWidth
		) {
			this.next.classList.add("is-active");
		} else {
			this.next.classList.remove("is-active");
		}
	}
}
