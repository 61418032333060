import {Element} from "@/scripts/extends";

export default class LinkArrow extends Element {
	mounted() {
		let buttonText = this.el.innerText;
		this.el.innerHTML = this.createLinkHtml(buttonText);
	}

	createLinkHtml(buttonText) {
		return `<span class="c-link__text ">${buttonText}</span>
		<span class="c-icon text-accent-yellow-600  group-hover:-translate-x-1 transition duration-200 w-5 h-5 -mt-[3px] stroke-current c-icon--after">
		<svg class="c-icon__asset" width="19" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
		<path d="M13.4167 0L12.0462 1.28156L19.3104 8.28H0V10.12H19.3104L12.0462 17.0872L13.4167 18.4L23 9.2L13.4167 0Z" fill="currentColor"></path>
		</svg>
		</span>`;
	}
}
