import { Element } from "@/scripts/extends";

export default class TrackingButton extends Element {

	events() {
		this.el.addEventListener("click", (e) => this.onClick(this.el, e));
	}

	onClick(el) {
		if (window.dataLayer) {
			let dataLayerProps = el.getAttribute("data-layer");

			if (dataLayerProps) {
				dataLayerProps = JSON.parse(dataLayerProps);
				if (!('page_url' in dataLayerProps)) {
					dataLayerProps['page_url'] = window.location.href;
				}

				if (dataLayerProps) {
					window.dataLayer.push(dataLayerProps);
				}
			}
		}
	}

	destroy() {
		this.el.removeEventListener("click", () => this.onClick());
	}
}
